
import Header from '../Navigation/Header.vue'
import Footer from '../Navigation/Footer.vue'

export default {
  components: {
		appFooter: Footer,
		appHeader: Header
	}

}